import request from '@/utils/request'


// 查询用户-用户搜索记录列表
export function listSearch(query) {
  return request({
    url: '/user/search/list',
    method: 'get',
    params: query
  })
}

// 查询用户-用户搜索记录分页
export function pageSearch(query) {
  return request({
    url: '/user/search/page',
    method: 'get',
    params: query
  })
}

// 查询用户-用户搜索记录详细
export function getSearch(data) {
  return request({
    url: '/user/search/detail',
    method: 'get',
    params: data
  })
}

// 新增用户-用户搜索记录
export function addSearch(data) {
  return request({
    url: '/user/search/add',
    method: 'post',
    data: data
  })
}

// 修改用户-用户搜索记录
export function updateSearch(data) {
  return request({
    url: '/user/search/edit',
    method: 'post',
    data: data
  })
}

// 删除用户-用户搜索记录
export function delSearch(data) {
  return request({
    url: '/user/search/delete',
    method: 'post',
    data: data
  })
}
